html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-size: 16px;
}

@media screen and (min-width: 600px) {
    body {
        font-size: 30px;
    }
}

* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

button {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}
