.information-screen {
    background-color: $color-grey-darker;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 25px 20px 25px;
    text-align: center;
    overflow-y: auto;

    .top-bar {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 50px;
        background: linear-gradient($color-grey-darker 60%, transparent);
    }

    h1 {
        width: 75%;
        text-transform: uppercase;
        font-family: $font-titillium-bold;
        font-size: 24px;
        line-height: 22px;
    }

    .info-item {
        font-family: $font-titillium-light;
        font-size: 16px;
        line-height: 21px;

        p {
            margin-top: 10px;
        }

        &:first-of-type {
            margin-top: 46px;
        }

        &:last-of-type {
            margin-bottom: 50px;
        }

        .images {
            display: inline-flex;

            img {
                margin: 0 10px;
            }
        }
    }
}
