.desktop-screen {
    width: 100%;
    height: 100%;
    background-image: url('../../../images/desktop_background.png');
    background-repeat: no-repeat;
    background-size: cover;

    &__container {
        height: 100%;
        background-image: $background-gradient;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 300px;
        font-family: $font-titillium-light;
        text-align: left;
        color: #ffffff;

        img {
            margin: 10px 0;
        }

        h3 {
            width: 500px;
            margin: 20px 0 14px 0;
            line-height: 37px;
            font-size: 32px;
        }

        p {
            width: 500px;
            font-size: 22px;
        }
    }
}
