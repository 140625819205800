#root {
    height: 100%;
}

.app-container, .app-router {
    height: 100%;
    font-family: $font-titillium-light;
    color: #ffffff;
    width: 100%;
    background-image: url('../../images/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto;
}

.field-error {
    font-family: $font-titillium-bold;
    color: red;
    font-size: 14px;
}

.top-bar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 50px;
    background: linear-gradient(rgba(#BC234F, 0.64) 60%, transparent);
}

.lang-container {
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 8px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;

    .label {
        font-size: 14px;
        margin-right: 8px;
    }

    .select {
        font-size: 14px;
        background-color: $color-text-field-bg;
        border-radius: 4px;
        justify-content: space-between;
        width: 95px;

        select {
            background-color: transparent;
            border: none;
            font-size: 12px;
        }
    }
}