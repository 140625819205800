.app-screen {
    height: 100%;

    &__container {
        height: 100%;
        background-image: $background-gradient;
        font-family: $font-titillium-light;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &__entry-page {
            padding: 12% 5%;
            min-height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            &.not-found {
                font-family: $font-titillium-bold;
                font-size: 30px;
                justify-content: center;
                align-items: center;
            }

            img {
                max-width: 100%;
                max-height: 100%;
            }

            > .centered {
                width: 100%;

                .big-text {
                    font-size: 32px;
                    line-height: 48px;
                }

                .small-text {
                    line-height: 24px;
                    font-size: 16px;
                    margin-bottom: 7px;
                }

                > .semibold {
                    text-transform: uppercase;
                    font-family: $font-titillium-semibold;
                    font-size: 13px;
                }

                button {
                    margin: 17px 0 8px 0;
                }
            }

            > .information {
                .item {
                    width: 100%;
                    display: inline-flex;
                    font-size: 12px;

                    &.with-margin {
                        margin-bottom: 15px;
                    }

                    .label {
                        text-transform: uppercase;
                    }

                    .semibold {
                        font-family: $font-titillium-semibold;
                    }
                }
            }

        }

        &__block-orientation-page {
            padding: 30px 20px;
            height: 100%;
            width: 100%;

            .centered {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                > img {
                    height: 150px;
                    margin: 150px 0px 50px;
                }

                > div {
                    text-align: center;
                    font-size: 18px;

                    .bold {
                        font-family: $font-titillium-semibold;
                    }
                }
            }
        }

        &__welcome-page {
            padding-top: 30px;
            padding-bottom: 30px;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            background-image: $background-gradient;

            &.final-screen {
                justify-content: flex-start;
                padding-top: 50px;
            }

            &__final-btn-wrapper {
                width: 100%;
                flex: 0 0;
                padding: 0 10px;
                margin-top: 10px;
            }

            > img {
                padding-left: 20px;
                padding-right: 20px;
            }

            > .title-container {
                padding-left: 20px;
                padding-right: 20px;

                h4 {
                    font-weight: normal;
                    font-size: 19px;
                    line-height: 29px;
                }

                img {
                    width: 90%;
                    height: auto;
                }
            }

            > .centered {
                width: 100%;

                > .icon-container {
                    padding-left: 20px;
                    padding-right: 20px;
                    width: fit-content;
                    object-fit: contain;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 20px;

                    img {
                        display: block;
                        height: 92px;
                    }

                    .svg-icon {
                        z-index: 2;
                        position: absolute;
                        align-self: center;
                    }
                }

                > .final-icon-container {
                    display: flex;
                    flex-direction: column;
                    padding-left: 20px;
                    padding-right: 20px;

                    .svg-icon:last-of-type {
                        margin-top: 19px;
                    }

                    .svg-icon {
                        svg {
                            width: 60px;
                            height: 60px;
                        }
                    }
                }

                .big-text {
                    font-size: 22px;
                    line-height: 25px;
                }

                .small-text {
                    line-height: 24px;
                    font-size: 16px;
                    margin-bottom: 7px;
                }

                > .semibold {
                    font-family: $font-titillium-semibold;
                    font-size: 13px;
                }

                p {
                    padding: 0 20px;
                    font-size: 14px;
                    line-height: 21px;
                }

                .step {
                    width: 100%;
                    padding: 12px 35px;
                    display: inline-flex;
                    align-items: center;
                    cursor: pointer;

                    .svg-icon {
                        margin-right: 5px;
                    }

                    > div {
                        width: 100%;
                        line-height: 17px;

                        .title {
                            font-family: $font-titillium-bold;
                            font-size: 24px;
                        }

                        .subtitle {
                            width: 100%;
                            font-family: $font-titillium;
                            font-size: 13px;
                        }
                    }

                    &.green {
                        position: relative;
                        margin-top: 22px;
                        background-color: $color-green-71AC86;
                        box-shadow: 0 3px 6px #00000064;
                    }

                    &.purple {
                        background-color: $color-purple;
                        box-shadow: 0 3px 6px #00000048;
                        opacity: 0.45;
                    }
                }

            }

            > .bottom {
                padding-left: 20px;
                padding-right: 20px;

                .svg-icon {
                    svg {
                        width: 35px;
                        height: 35px;
                    }
                }

                p {
                    font-family: $font-titillium-semibold;
                    font-size: 14px;
                }

                > .bold {
                    font-family: $font-titillium-semibold;
                    font-size: 21px;
                    line-height: 28px;
                }

                .regular {
                    font-family: $font-titillium;
                    font-size: 13px;
                    line-height: 21px;
                }

                h2 {
                    margin-top: 5px;
                    font-family: $font-titillium-bold;
                    font-size: 29px;
                    line-height: 44px;
                    color: $color-purple-light;
                }
            }

        }
    }
}
