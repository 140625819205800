.step-screen {
    min-height: 100%;
    background-image: $background-gradient;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 80px;

    &__top-bar {
        display: flex;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        align-items: center;
        justify-content: space-between;
        flex: 0 0 80px;
        height: 80px;
        z-index: 10;
        width: 100%;
        padding: 30px 16px 0;
        background-image: linear-gradient(270deg, rgba(#0B3477, 1) 0%, rgba(#D124A8, 1) 100%);
        box-shadow: 0px 3px 6px #00000064;

        img {
            height: 19px;
        }

        > .title {
            font-family: $font-titillium;
            font-size: 11px;
            letter-spacing: 2.64px;
            line-height: 15px;
            text-transform: uppercase;
        }
    }

    &__content {
        padding: 20px;
        width: 100%;
        max-width: 500px;
        overflow-y: auto;

        &.glass-welcome {
            align-items: flex-start;
            width: 100%;
            min-height: 100%;
            display: flex;
            flex-direction: column;
            flex: 1;

            > img {
                padding-left: 22px;
            }

            .title-container {
                padding-bottom: 22px;
                font-family: $font-titillium-light;
                font-size: 19px;
                line-height: 29px;

                &.final-glass-screen {
                    margin-top: 15px;
                    flex-grow: 1;

                    .svg-icon {
                        margin-bottom: 10px;
                    }

                    > .regular {
                        font-family: $font-titillium;
                        font-size: 13px;
                        line-height: 21px;
                    }

                    > .bold {
                        font-family: $font-titillium-semibold;
                        font-size: 16px;
                        line-height: 23px;
                        margin-bottom: 10px;
                    }

                }

                img {
                    width: 60%;
                    height: auto;
                }

                h3 {
                    margin-top: -18px;
                    text-transform: uppercase;
                    font-family: $font-titillium-semibold;
                    line-height: 21px;
                    font-size: 16px;

                    &.lowercase {
                        margin-top: 20px;
                        font-size: 14px;
                        text-transform: unset;
                    }
                }
            }

            .small {
                font-family: $font-titillium;
                font-size: 12px;
            }

            .ml-8 {
                font-family: $font-titillium-semibold;
                margin-left: 4px;
                cursor: pointer;
                font-size: 13px;
            }

            .error {
                padding: 3px 0;
                margin-bottom: 10px;
                width: 100%;
                text-align: center;
                background-color: rgba(#FFFFFF, 0.4);

                > p {
                    padding-top: 2px;
                    font-family: $font-titillium-semibold;
                    color: red;
                    font-size: 15px;
                }
            }

            .bottom {
                padding: 0 22px;

                &.glass-final {
                    width: 100%;
                    margin-left: 20px;
                    margin-top: 40px;
                    padding-left: 0;
                    padding-top: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    &.success {
                        border-top: 1px solid $color-green-71AC86;
                    }

                    &.damaged {
                        border-top: 1px solid $color-pink;
                    }
                }

                .svg-icon {
                    svg {
                        width: 35px;
                        height: 35px;
                    }
                }

                p {
                    font-family: $font-titillium-semibold;
                    font-size: 14px;
                }

                > .bold {
                    font-family: $font-titillium-semibold;
                    font-size: 21px;
                    line-height: 28px;
                }

                .regular {
                    font-family: $font-titillium;
                    font-size: 13px;
                    line-height: 21px;
                }

                h2 {
                    margin-top: 5px;
                    font-family: $font-titillium-bold;
                    font-size: 29px;
                    line-height: 44px;
                    color: $color-purple-light;
                }
            }

            .button-container {
                flex-grow: 1;
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                position: relative;

                img {
                    width: 100%;
                    height: auto;
                    mix-blend-mode: screen;
                    position: absolute;
                }

                button {
                    text-align: center;
                    justify-content: flex-end;
                    max-width: 500px;
                    margin: 0 22px 20px 22px;

                    span:first-of-type {
                        width: 100%;
                    }

                    .svg-icon {
                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }

        }

        .button-container {
            width: 100%;
            position: relative;

            img {
                width: 100%;
                height: auto;
                mix-blend-mode: screen;
                position: absolute;
            }
        }

        &.information-step {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        &__title-container {
            width: 100%;
            display: inline-flex;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid $color-purple;

            .svg-icon {
                margin-right: 15px;
            }

            > div {
                width: 100%;

                .title {
                    font-family: $font-titillium-bold;
                    font-size: 24px;
                    line-height: 17px;
                }

                .subtitle {
                    width: 100%;
                    font-family: $font-titillium;
                    font-size: 13px;
                }
            }
        }

        &__middle {
            padding-top: 15px;
            padding-bottom: 15px;
            width: 100%;
            border-bottom: 1px solid $color-purple;

            &.information-step {
                border: none;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }

            &.without-border {
                border-bottom: none;
            }

            .text-toggle-container {
                width: 100%;
                justify-content: space-between;
                display: inline-flex;
                align-items: center;

                div {
                    p {
                        font-family: $font-titillium;
                        font-size: 16px;
                        margin: 10px 0;
                    }
                }

                .toggle-container {
                    display: inline-flex;
                    align-self: flex-start;
                    align-items: center;

                    > p {
                        font-family: $font-frutiger-45-light;
                        line-height: 17px;
                        font-size: 14px;
                    }

                    .react-switch {
                        margin: 0 9px;
                    }
                }
            }

            .title {
                font-family: $font-titillium-bold;
                font-size: 18px;
                line-height: 9px;
                text-transform: uppercase;
                align-self: flex-start;
            }

            .help-text {
                font-family: $font-titillium;
                font-size: 13px;
                line-height: 21px;
                margin-top: 10px;
            }

            p {
                font-family: $font-titillium;
                font-size: 11px;
                line-height: 16px;

                &.info {
                    font-size: 13px;
                }

                &.field-description {
                    margin: 12px 0 8px 0;
                    font-size: 16px;
                }

                &.with-margin {
                    margin-bottom: 10px;
                }

                &.with-top-margin {
                    margin-top: 10px;
                }
            }

            .bold {
                font-family: $font-titillium-bold;
                font-size: 12px;
                line-height: 16px;
            }

            > button {
                margin-top: 20px;
                text-align: center;
                justify-content: flex-end;
                max-width: 500px;

                span {
                    width: 100%;
                }

                .svg-icon {
                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            .observations-container {
                margin-top: 20px;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                p {
                    margin-top: 9px;
                    font-size: 13px;
                    line-height: 17px;
                }

                .text-area-container {
                    flex-grow: 1;

                    .text-area {
                        height: 100%;

                        textarea {
                            height: 100%;
                        }
                    }
                }
            }

            .buttons-container {
                width: 100%;
                display: inline-flex;
                margin-top: 10px;

                button {
                    text-align: center;
                    justify-content: flex-end;
                    max-width: 500px;

                    span:first-of-type {
                        width: 100%;
                    }

                    .svg-icon {
                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                button:first-of-type {
                    margin-right: 6px;
                }

                button:last-of-type {
                    margin-left: 6px;
                }
            }

            &__pictures_container {
                margin-top: 10px;

                &.glass-pictures {
                    .row {
                        width: 100%;
                        display: inline-flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 20px;

                        &.with-margin {
                            margin-top: 10px;
                        }
                    }
                }

                > .error {
                    padding: 3px 0;
                    margin-bottom: 10px;
                    width: 100%;
                    text-align: center;
                    background-color: rgba(#FFFFFF, 0.4);

                    > p {
                        padding-top: 2px;
                        font-family: $font-titillium-semibold;
                        color: red;
                        font-size: 15px;
                    }
                }

                .row {
                    width: 100%;
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;

                    &.with-margin {
                        margin-top: 10px;
                    }

                    .upload-item {
                        height: 97px;
                        width: 97px;
                        background-color: $color-purple-C026A1;
                    }

                    .empty-item {
                        height: 97px;
                        width: 97px;
                    }
                }

                &.glass-control {
                    &__pictures {
                        display: grid;
                        grid-template-columns: 1fr 1fr auto;
                        row-gap: 20px;
                        margin-bottom: 30px;
                    }
                }
            }

            .error {
                padding: 3px 0;
                margin-bottom: 10px;
                width: 100%;
                text-align: center;
                background-color: rgba(#FFFFFF, 0.4);

                > p {
                    padding-top: 2px;
                    font-family: $font-titillium-semibold;
                    color: red;
                    font-size: 15px;
                }
            }

            .glass-control {
                &__msg {
                    width: 100%;
                    text-align: center;
                    margin: 20px 0;
                    font-size: 20px;
                    line-height: 24px;
                }

                &__buttons-container {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 10px;

                    &.centered {
                        grid-template-columns: 1fr;
                        justify-items: center;
                    }

                    &--one-button {
                        grid-template-columns: 1fr;
                    }

                    > button:first-child {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}