@font-face {
    font-family: 'TitilliumWeb';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/titillium_web_regular-webfont.woff2') format('woff2'),
    url('../../fonts/titillium_web_regular-webfont.woff') format('woff');
}

@font-face {
    font-family: 'TitilliumWebLight';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/titillium_web_light-webfont.woff2') format('woff2'),
    url('../../fonts/titillium_web_light-webfont.woff') format('woff');
}

@font-face {
    font-family: 'TitilliumWebSemibold';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/titillium_web_semibold-webfont.woff2') format('woff2'),
    url('../../fonts/titillium_web_semibold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'TitilliumWebBold';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/titillium_web_bold-webfont.woff2') format('woff2'),
    url('../../fonts/titillium_web_bold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger45Light';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/frutiger_45_light-webfont.woff2') format('woff2'),
    url('../../fonts/frutiger_45_light-webfont.woff') format('woff');
}
