.btn {
    font-family: $font-titillium-semibold;
    display: inline-block;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    padding: 5px 10px;
    border: none;
    -webkit-transition: all .25s ease-in-out;
    border-radius: 4px;
    transition: all .25s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    opacity: 1;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: 21px;
    box-shadow: 0px 1px 3px #00000033;

    &--purple {
        background-color: $color-purple;
        color: #ffffff;

        .svg-icon {
            width: 15px;
            height: 15px;

            svg {
                width: 15px;
                height: 15px;
                fill: #ffffff;
            }
        }
    }

    /* &--light-purple {
        opacity: 0.48;
        background-color: $color-purple-lighter;
        color: $color-grey-E5E5E5;
    }

    &--dark-purple {
        background-color: $color-purple-2C4285;
        color: #ffffff;
    }

    &--link {
        color: #ffffff;
        text-transform: none;
        text-decoration: underline;
        border: unset;
    } */

    &--green {
        background-color: $color-green-71AC86;
        color: #ffffff;

        .svg-icon {
            width: 20px;
            height: 20px;

            svg {
                width: 20px;
                height: 20px;
                fill: #ffffff;
            }
        }
    }

    &--white{
        background-color: #fff;
        color: #000;

        .svg-icon {
            width: 20px;
            height: 20px;

            svg {
                width: 20px;
                height: 20px;
                fill: #000;
            }
        }
    }

    &--with-icon {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        text-transform: uppercase;

        &--left {
            padding-left: 10px;

            .svg-icon {
                order: 1;
                margin: 0 10px 0 0;
            }
        }

        &--right {
            padding-right: 15px;

            .svg-icon {
                margin: 0 0 0 10px;
            }
        }
    }

    &--just-icon {
        padding: 0;
        align-items: center;

        .svg-icon {
            width: auto !important;
            height: auto !important;

            svg {
                width: 22px;
                height: 22px;
            }
        }
    }

    &--full-width {
        width: 100%;
    }

    &--upload-photo {
        height: 110px;
        width: 110px;
        border-radius: 0;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px;
        text-align: left;

        &.purple {
            background-color: $color-purple-C026A1;
        }

        &.dark-purple {
            background-color: $color-purple-dark;
        }

        &.darker-purple {
            background-color: $color-purple-darker;
        }

        &.blue {
            background-color: $color-blue-dark;
        }

        &.glass-icon {
            padding: 0;

            .svg-icon {
                svg {
                    width: 110px !important;
                    height: 110px !important;
                }
            }
        }

        &.no-top-margin {
            .title-top {
                .text-small {
                    margin-top: 2px !important;
                }
            }

        }

        &.text-lower {
            .title-top {
                top: 20px !important;
            }
        }

        &.position-relative {
            position: relative;

            .loading-container {
                width: 110px;
                height: 110px;
                position: absolute;
                bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: black;
                background: linear-gradient(transparent, #FFFFFF);
                font-weight: 900;
            }

            .more-top {
                position: absolute;
                left: 4px;
                top: -2px;
                font-family: $font-titillium-semibold;
                font-size: 24px;
                text-shadow: 1px 1px #0000004d;
            }

            .title-top {
                position: absolute;
                top: 15px;
                width: 100%;

                .text-small {
                    text-transform: none;
                    text-align: center;
                    font-size: 9px;
                }
            }
        }

        &.only-icon {
            background-color: rgba($color-purple-AE6AB5, 0.66);
            justify-content: center;
            align-items: center;

            .svg-icon {
                width: 73px !important;
                height: 73px !important;

                svg {
                    width: 73px !important;
                    height: 73px !important;
                }
            }
        }

        .svg-icon {
            order: 1;
            width: 15px !important;
            height: 15px !important;

            svg {
                fill: #FFFFFF;
                width: 15px !important;
                height: 15px !important;
            }
        }

        .uppercase {
            font-family: $font-titillium;
            font-size: 11px;
            line-height: 13px;
            text-transform: uppercase;
        }

        .bold {
            font-family: $font-titillium-bold;
            font-size: 10px;
            line-height: 13px;
            text-transform: none;
        }

        .bottom {
            width: 100%;

            > p {
                font-size: .5rem;
                text-align: center;
                padding-bottom: .4rem;
            }

        }

        > div {
            order: 2;
        }
    }

    .svg-icon {
        width: 20px;
        height: 20px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    > span {
        order: 2;
    }
}
