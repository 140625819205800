.form {
    &__field-container {
        margin: 5px 0;

        &__label {
            //margin-left: 11px;
            font-family: $font-titillium;
            line-height: 20px;
            letter-spacing: 0.12px;
        }

        &__text-area {
            font-family: $font-frutiger-45-light;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.15px;
            background-color: $color-text-field-bg;
            display: inline-flex;
            align-items: center;
            width: 100%;
            padding: 15px 16px;
            border-radius: 4px;
            justify-content: space-between;

            textarea {
                width: 100%;
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.15px;
                background-color: transparent;
                border: none;
                resize: none;
                color: $color-purple-4E3E86;
            }
        }

        &__text-field {
            font-family: $font-frutiger-45-light;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.15px;
            background-color: $color-text-field-bg;
            display: inline-flex;
            align-items: center;
            width: 100%;
            padding: 0 16px;
            border-radius: 4px;
            height: 56px;
            justify-content: space-between;

            .svg-icon {
                svg {
                    fill: rgba(#000000, 0.3);
                    width: 18px;
                    height: 18px;
                }
            }

            input {
                width: 100%;
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.15px;
                background-color: transparent;
                color: $color-purple-4E3E86;
                border: none;
            }
        }
    }

}
