$color-grey-00000033: #00000033;
$color-grey-darker: #262626;

$color-purple: #A81EAD;
$color-purple-C026A1: #C026A1;
$color-purple-light: #E437EA;
$color-purple-dark: #862B93;
$color-purple-darker: #443082;
$color-purple-AE6AB5: #AE6AB5;
$color-purple-4E3E86: #4E3E86;

$color-pink: #E12E9A;

$color-blue-dark: #173377;
$color-green-71AC86: #71AC86;

$color-text-field-bg: rgba(#FFFFFF, 0.81);

//$background-gradient: linear-gradient(180deg, rgba(209, 36, 168, 0.54), rgba(11, 52, 119, 0.54));
$background-gradient: linear-gradient(180deg, rgba(#BC234F, 0.64) 0%, rgba(#563F87, 0.64) 73%, rgba(#4145A2, 0.54) 100%, rgba(#144DA0, 0.64) 100%);
//transparent linear-gradient(180deg, #144DA0 0%, #4145A2 0%, #563F87 27%, #BC234F 100%) 0% 0% no-repeat padding-box;

//$background-gradient-70-opacity: linear-gradient(180deg, rgba(209, 36, 168, 0.70), rgba(11, 52, 119, 0.70));
//$background-gradient-70-opacity: linear-gradient(180deg, rgba(#BC234F, 0.61) 0%, rgba(#563F87, 0.61) 73%, rgba(#4145A2, 0.61) 100%, rgba(#144DA0, 0.61) 100%);
//$background-gradient-90-opacity: linear-gradient(180deg, rgba(#BC234F, 0.70) 0%, rgba(#563F87, 0.70) 73%, rgba(#4145A2, 0.70) 100%, rgba(#144DA0, 0.70) 100%);

$font-titillium: 'TitilliumWeb', sans-serif;
$font-titillium-light: 'TitilliumWebLight', sans-serif;
$font-titillium-semibold: 'TitilliumWebSemibold', sans-serif;
$font-titillium-bold: 'TitilliumWebBold', sans-serif;
$font-frutiger-45-light: 'Frutiger45Light', sans-serif;
