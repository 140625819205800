.document-upload {
    input {
        &[type='file'] {
            display: none;
        }
    }

    .image-preview {
        height: 97px;
        width: 97px;
        display: flex;
        align-items: center;
        position: relative;

        .icon-box {
            position: absolute;
            top: 3px;
            right: 3px;
            z-index: 2;
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba($color-purple-C026A1, 0.68);

            .svg-icon {
                svg {
                    fill: #FFFFFF;
                    width: 10px;
                    height: 10px;
                }
            }
        }


        img {
            margin: auto;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}
