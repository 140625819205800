/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.camera-container {
    position: absolute;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 20;
    $camera-container: &;

    @media (orientation: portrait) {
        transform: rotate(90deg) translateY(-100%);
        transform-origin:top left;  
        height: 100vw;
        width: 100vh;
    }

    canvas,
    input {
        display: none;
    }

    video {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }

    &--side {
        #{$camera-container}__mask {
            height: 100%;
            background-position: center;
            background-size: 215vw 120vh;
           
        }

        #{$camera-container}__transparency {
            flex: 1;

            &:nth-of-type(2) {
                flex: .3;
            }
        }
    }

    &--front-windshield {
        #{$camera-container}__mask {
            height: 100%;
            background-position: center;
            background-size: 255vw 120vh;
        }
    }

    &--skew-windshield {
        #{$camera-container}__mask {
            height: 100%;
            background-position: center;
            background-size: 255vw 135vh;
        }

        #{$camera-container}__transparency {
            flex: 1;

            &:nth-of-type(2) {
                flex: 0;
            }
        }
    }

    &--hidden {
        visibility: hidden;
    }

    &__transparency {
        display: flex;
        position: relative;
        width: 100%;
        background-color: rgba(#171B26, .89);
        z-index: 1000;

        @media (orientation: portrait) {
            flex: 1;
            width: 100%;
        }
    }
    
    &__mask {
        position: relative;
        z-index: 1000;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 112%;
        width: 100%;
        height: 100%;

        &--mirrored {
            transform: scaleX(-1);
            background-position: 46% center;
        }

        @media (orientation: portrait) {
            height: 70vw;
            width: 100%;
        }
    }


    &__photo-button {
        width: 20vh;
        height: 20vh;
        top: 50vh;
        right: 2vw;
        margin-top: -10vh;
        margin-left: -10vh;
        position: absolute;
        z-index: 10000;
        cursor: default;
        $photo-button: &;
        opacity: 0.8;


        @media (orientation: portrait) {
            top: 50vw;
            right: 8vh;
            width: 25vw;
            height: 25vw;
            max-width: 20vw;
            margin-top: -12.5vw;
            margin-left: -12.5vw;
        }

        &:active {
            #{$photo-button}__ring {
                opacity: 1;
            }

            #{$photo-button}__circle {
                opacity: 0.5;
            }
        }

        &__circle {
            position: absolute;
            top: 12px;
            left: 12px;
            bottom: 12px;
            right: 12px;
            border-radius: 100%;
            background-color: white;
            transition: all 0.25s;
        }

        &__ring {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 100%;
            border: 6px solid white;
            opacity: 0.8;
            transition: all 0.25s;
        }
    }


    &__close-button {
        width: 40px;
        height: 40px;
        top: 5vw;
        left: 5vw;
        fill: #fff;
        position: absolute;
        z-index: 10000;
        cursor: default;
        border: 1px solid #fff;
        border-radius: 50%;
        color: #fff;
        font-size: 14px;
        text-align: center;
    }

    @media (max-aspect-ratio: 11/6) {
        @media (orientation: landscape) {
            &__transparency {
                flex: 1;
            };
    
            &__mask {
                height: 97%;
            }
        }
    }

    @media (max-aspect-ratio: 900/680) {
        @media (orientation: landscape) {
            &__mask {
                height: 50%;
            }
        }
    }
}

