.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  background-color: rgba($color-purple-darker, 0.85);
  top: 0;
  right: 0;

	.close-button {
    	position: absolute;
		top: 10vh;
		right: 3vw;
		
		@media (orientation: landscape) {
			top: 22vh;
			right: 2vw;
		}

    	svg {
			fill: #FFF;
			width: 18px;
			height: 18px;
		}
	}

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .image-container {
      padding: 20px;
      position: relative;
      height: calc(100% - 150px);

      .top-container {
        position: absolute;
        display: flex;
        background-color: rgba(white, 0.8);
        justify-content: space-between;
        right: 20px;
        left: 20px;

        .icon-box {
          height: 28px;
          width: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba($color-purple-C026A1, 0.80);
  
          .svg-icon {
              svg {
                  fill: #FFFFFF;
                  width: 10px;
                  height: 10px;
              }
          }
        }
  
        .error-container {
          color: $color-grey-darker;
          font-family: $font-titillium-semibold;
          font-size: 12px;
          p {
            margin-left: 8px;
          }

          .red {
            color: $color-purple-C026A1;
            font-size: 14px;
          }
        }
      } 
    }

    .info-container {
      margin-top: 60px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.document-upload {
      .info-container {
        display: flex;
        column-gap: 30px;

        .btn {
          background-color: $color-purple-dark;

          .uppercase {
            font-size: .9rem;
          }
        }
      }
    }
  }
}
