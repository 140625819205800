.video-call {
    height: 100%;
    width: 100%;

    &__information-screen {
        background-image: $background-gradient;
        min-height: 100%;
        width: 100%;
        padding: 50px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        > div {
            width: 100%;
        }

        img {
            margin-bottom: 10px;
        }

        h1 {
            font-family: $font-titillium-semibold;
            font-size: 24px;
        }

        h3 {
            font-family: $font-titillium-bold;
            font-size: 18px;
        }

        .buttons {
            display: inline-flex;
            width: 100%;

            .btn {
                margin: 0 3px;
            }
        }
    }

    &__video-container {
        width: 100%;
        height: 100%;
        background-color: #000000;
        position: relative;

        .video {
            width: 100%;
            height: 100%;
            position: relative;

            &.hidden {
                display: none;
            }
        }

        .controls-container {
            display: inline-flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 40px;
            left: 0;
            z-index: 3;

            .btn {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                line-height: 1px;

                &:nth-of-type(2) {
                    margin: 0 5px;
                }

                .svg-icon {
                    width: 15px !important;
                    height: 15px !important;
                    opacity: 0.5;

                    svg {
                        fill: #FFFFFF;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }

        &__calling {
            height: 100%;
            width: 100%;
            z-index: 2;
            background-image: $background-gradient;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

}